import { UseCase } from "../../core/helpers/use-case";
import { Either } from "../../core/helpers/either";
import { Failure } from "../../core/helpers/failure";
import { UserRepository } from "../repository/user-repository";
import { Injectable } from "@angular/core";
import { CreateUserDto } from "../dto/create-user-dto";

@Injectable({
    providedIn: 'root'
})
export class RegisterUserDataUsecase implements UseCase<boolean, CreateUserDto>{
    constructor(private _userRepository: UserRepository) { }

    execute(params: CreateUserDto): Promise<Either<Failure, boolean>> {
        return this._userRepository.registerUserData(params);
    }

}